import "../../core/src/modules/explorer/views/tree/node.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/modules/explorer/views/tree/node.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61W7W7rKBD936dAkVZqtSJyPpq2VJX2PVbVamLGDjcYLMDJzV3dd18Z7AQcxze73V8JZhiGM2fOzB97PBUGKrTkr8UprzeH45b8/UBI9pv/IUTXkAt3YmTx/kDIzwdCNhN7L9d7Wb/3OnHubeLcIrt58OfDvA974U1yLbVhRKgdGuHeY4OlN+DC1hJOrYkUCmkh8XvrC6QoFRUOK8tIjsqhaT9vteFoqAEuGsvIuvbGDr876k8wIrFw7yM3E3IU3O2YD79dVkLRHYpy5xhZZcFRY9FQixJzx4jSCr2nxtjWVa1FH0YNnAtVMpL1IRRaOWrFD2TkAOaRUigK/x7/vXryp7QVTmjFiEEJThy8+wpMKRR1umbEhxFjxHb6gMYjtYV8XxrdKD64wptQ/96n9PSfHBxQyNurPmbONDj7/JovLixsJfLEWw8QxwIaGaOf+PZZsphrxcGczpeQHliKB1TO9sCPXG6gLIUqk8sv/Jw/J4dWKb9+QayOHJ7krWlH6fGUlVAzslgOkrX2F44/JvYEW6tl4/zXlq3drZ4AdNEx8UzWsOyJuhgy5DmAcEBTSH1kZCc4R3UuissGSilqK6x3vhMOqa0hxzbAo4F6ApoIjrG0HpYhs7URFZiTz6gv5T7mxDraSQm2+VfpMsF1dhvZVLd+TbGu0gbxpAI39CKUcAJkGsT5Y+T9Zfxx3xrrRNGWgnKoXPzAaZouB7To1zfyI3KtkvxEarUcMurVx9rzbzPgX7e+N08TLxxI+bKTcgOqR9L/L7SpSDZf2vvfFz8mKEeupYTaDnTr7J8Rox04fKRvGcdy1MWo8kXis7qLZq9fEvS3S9ijEBEEi1Qoqpu01759HQZIKXz1MmBayRPNd0LyYWe/Lojcm8Str8t/11lpEMYAS38ou9lFI898GtvLRk+YqBGdq6srpwFFN4NKwU7wJ+PBAL0z2GbGOtPkrfXHrII9Brhmn8HGoixCk0PulftjVoC0OPtkULiONedKms2SQtxKne9vy+EPKhQ/6/id1Ov701gawrwSoZaDzB/9bEivDjylPawdwP5viMK+rlGNoQZKVBBQuczVC0uEKlp6ou9YYJKwipDeftR7HeXEeWCgXBjMOyLoIzV4QGMnxoXidlKvEP19GtFs/twFd5P2IepLYrfaOV1NdtBLj02iHhewKz0oWJg7/6PUFXfNrl66ajCoUr3bxT1stUx7WL+Om+B6kBzhHUQWi2Hxf7uyeAluk9ln2d0l0bV9oR25PJloNl+vojCOnf06S+HeX+lku/8PbP95dSYOAAA=\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var collapseContentPlaceholder = '_1ycp6vwa';
export var collapsedIcon = '_1ycp6vw9';
export var collapsedIconContainer = '_1ycp6vw8';
export var contentContainer = '_1ycp6vwc';
export var draggedOverEffect = '_1ycp6vwe';
export var draggingContainer = '_1ycp6vwd';
export var iconContainer = '_1ycp6vw7';
export var itemContent = '_1ycp6vw5';
export var itemMain = '_1ycp6vw3';
export var itemRenameAnchor = '_1ycp6vw4';
export var itemRoot = '_1ycp6vw2';
export var levelIndent = 'var(--_1ycp6vw0)';
export var linkItemRoot = '_1ycp6vw1';
export var mobileCollapsedIconContainer = '_1ycp6vwi _1ycp6vw8';
export var mobileContentContainer = '_1ycp6vwk _1ycp6vwc';
export var mobileIconContainer = '_1ycp6vwh _1ycp6vw7';
export var mobileItemContent = '_1ycp6vwj _1ycp6vw5';
export var mobileItemMain = '_1ycp6vwg _1ycp6vw3';
export var mobileItemRoot = '_1ycp6vwf _1ycp6vw2';
export var postfix = '_1ycp6vw6';