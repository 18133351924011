import "../../core/src/components/affine/setting-modal/general-setting/editor/style.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/setting-modal/general-setting/editor/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VVwXKbMBC95yt06Ux8UMYmceoqt/5Ar73KaDHbCImRFoPTyb93kAwGTGyn094Ysbtv376n1YN+XbtVvWS/7xjLNDR852wt2OrljjGFvtTyIMKP9uBX5QmzA0+tITAUf3Awqv1ZoOE1KsoFW62XZUgoZNOdJfHs/e4hQq7+MeTyOmQSILcyfd05WxklWJ0jQZs1zohVcsBdToI9Lvd5e2b34DJta34QTFZkB5UfQ2WChjg5aXxmXSFYKkskqfEtQGTWEK+PNZ+Xy3PYvtxTKFdaj4TWCOZAS8I9zE4oDEShgzQGp1ZXhYkk3A4N31oiWwiWPI1A1gFkErOJ9EOvHt9AsL1095zLLEMDPJ4XizM+68gntdq6Sc4+4WEwHlJrlHSHxaCJ5wlTufVWV1ETsqVgoayGjMJnn/c15J20/9KGdYKtNkcVt9YpcIKtyoZ5q1GddablARxH41HB9xDNY9LilM+dVFh5wZ5i1eseTcEQuPaX1LgzHAkKfzruWWwusO8UWSURNc5gHZkOTBY+tST4ec/Xyy+Lq/I1fvEZqYYXhn+QFKc4iMvbu/JjD07LWKOUSqHZCZaUTWeyfo5odOjuOM7ZmV2c8rxObxyNgua4WTpzTG7Bt7gTbMN9LlW7h4w18Je0CRzhxOEyAJx55gOS/ZyOHCYOn9+GnqSj9vdOloI9j/htZ9dsdzE6rraiVoLTwc0LIJMF6qBhDg5p4KzByfkog2tL6cDQsFkhSi1TyK1W4ELnl2w6iB1OPL2UiKk1vHRYdO4eME3GW1jNK3emgS9lCnwLVAOYC9p274dgOSoVQ0f69sgwj/zxph8hjj0xi9o+ezz03UpeO1m+dO/XKR60xtKjHzSWjRu77eJelHCgxMTrn21ynuotRg4kuv1grCukHm2MZGSL7KFfj1cdOl6kt6zlHnTsxd0lqG1FNHH18VmPj8ZmVCr/D12P19b7Hw7PXW1WCgAA\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var InputContainer = 'lk5r1wa';
export var fontItem = 'lk5r1we';
export var fontItemContainer = 'lk5r1wd';
export var fontLabel = 'lk5r1wf';
export var menu = 'lk5r1w2';
export var menuTrigger = 'lk5r1w3';
export var notFound = 'lk5r1wh';
export var preViewLabelWrapper = 'lk5r1w1';
export var searchIcon = 'lk5r1wc';
export var searchInput = 'lk5r1wb';
export var selectedIcon = 'lk5r1wg';
export var settingWrapper = 'lk5r1w0';
export var shapeIndicator = 'lk5r1w9';
export var snapshot = 'lk5r1w7';
export var snapshotContainer = 'lk5r1w4';
export var snapshotLabel = 'lk5r1w8';
export var snapshotSkeleton = 'lk5r1w6';
export var snapshotTitle = 'lk5r1w5';