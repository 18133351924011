import "../../component/src/ui/modal/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/modal/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61W62rjOBT+n6c4WyikUAUnTTMddwplF/YFdmFZlrIcW8eJWlnySMqtS999keQ4tnOZduivhHP9vnOTH19oWxgsycK/47ycfd8+T+G/AUBhdBn+AOgKc+G2KST3A4C3AYDTfdU4qt4Gj4cBb88HBHAGlS20KdP4V6Kjv4dscnkFNkdJw2T0dXb1g+SnwiS7IOOrMxhnIeZh6J9CeIzqJ2D80q/j8WDjJLk8LNapxGfy3b0vX/LeZC1gb4PRLksWfCpthRNapVCIDXFvJZQlV3cgw/xlbvRScZZrqU0KKzRDxrAohCLWUpeao4xGIdcrE4rTpudQS6N1sEMlSowI9oswvk1KC4U2azTc3ndgj7wHU1pRINDy97KebakzIaPhD6isJkzilgyLLg3CVrj8PRXjwlYStykUkjaBoBRzxYSj0qaQk3JkvPh5aZ0otizXypFybdX7StcG9g9Hh6xYSslsbojUw4UzS7p4ioiRc6HmKSTwiygrbRwq1w3wgaLWtgVy+sPvzp+66vvsD1BsZL7MRM4yehVkhsloOrmG5BqS0e3dNYy7U8AKIaXnSGmn/5HhLvDNE5zDM1o5hrkTq8hmJWjNwj6ExjGFJe1Bfj3GzkrB6VftXL2DR8h9gWQ0ubFAaOlzKLSSfogB+uhp2jfSkg8bklenaMw+r0fnIeBJCHcfrWRTOB5CsqaoSQr3HcG4L5hEwVpwt9gtWOMdaC5IzBeur4slKIVix/WTqMdNo89R5v70rhbA4GZSbRqLOnljsAYGk6Q2yPSGWfEa9jXThpNhmQ6HpNDKeRX1DkOQZ2jpqrFa1ximSbhJ0pvtcI1Hs/v2UfCZYTKtQo79cTMk0c/fx14BvSIjccsqVNR+DQKrBXK97vlWuvI+tbK2DawNcrG0KYwnEZpeOs9jf5FOH71z8xEKfjAkoU8Hk9JIW11r/Nu9bgx72JPOtFLv/cDMarl0ocROVylMaqomRvWd+an3oIgfEOfmZcFmh+MyOzou09tI18yFYlk4T7uutHLOQ861NpxlhvAlhfDDvCRs3EI4YrbCnFKoDLG1wSr0dUWmkHodBF2vVvhFCH/wuB68ol7BSPEzL289+SyUfFrXuKYXZLh02svmHk9sQhvJKFT8d60dmb+EW/y2EJIbUu23NgY64mtoRcbGSQhYuTCU1yun16zWn+ZmHZru4y1CsNYoXnbKut0Tanbedw+mfu+T+KcT8Pl4rftwcy2XpdpvYijLw0WB0tLF08FXk/D1Z7Qi5Wx/ibuu/f3t+0Y6LcAvHwNcd3Y3wo8lcYEQbwig4jAMuw7fHmDm5yM+XD1CvfFqz91+CnbbQmo1tFgQQ0PIwudirbveX/63wdv/EnmWBxYOAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var closeButton = '_1cm6qyje';
export var confirmModalContainer = '_1cm6qyjj';
export var confirmModalContent = '_1cm6qyji';
export var heightVar = 'var(--_1cm6qyj1)';
export var minHeightVar = 'var(--_1cm6qyj2)';
export var modalContent = '_1cm6qyjd';
export var modalContentWrapper = '_1cm6qyjc';
export var modalDescription = '_1cm6qyjg';
export var modalFooter = '_1cm6qyjh';
export var modalHeader = '_1cm6qyjf';
export var modalOverlay = '_1cm6qyjb';
export var modalVTScope = '_1cm6qyj3';
export var promptModalContent = '_1cm6qyjk';
export var widthVar = 'var(--_1cm6qyj0)';